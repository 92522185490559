@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HelveticaNeue';
  src:
    local('HelveticaNeue'),
    url('assets/fonts/helvetica/HelveticaNeueLight.ttf') format('truetype');
}

body {
  margin: 0 auto;
  font-family: 'HelveticaNeue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus {
  outline: 0px !important;
  outline-offset: 0px !important;
  box-shadow: 0px 0px 0px 6.133333206176758px rgba(115, 186, 155, 0.25);
  border: 1.5px solid #73ba9b !important;
}

textarea:focus {
  outline: 0px !important;
  outline-offset: 0px !important;
  box-shadow: 0px 0px 0px 6.133333206176758px rgba(115, 186, 155, 0.25);
  border: 1.5px solid #73ba9b !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media only screen and (min-width: 1280px) {
  .split-colors {
    background: linear-gradient(to right, #21424b 70%, #77ba9c 70%);
  }
}

@keyframes shadow-pulse {
  0%,
  100% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  }
}

.animate-shadow {
  animation: shadow-pulse 2s infinite;
  padding: 2px;
  border-radius: 14px;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
